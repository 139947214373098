<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <div class="card border-0 shadow-none">
          <div class="card-body py-1 px-2">
            <button type="button" class="btn btn-xs btn-light me-2" @click="clearFilters">
              <i class="far fa-times"></i>
            </button>
            <button
              type="button"
              class="btn btn-xs me-2"
              :class="sectionFilter === s ? 'bg-primary text-white' : 'btn-dark'"
              v-for="s in sections"
              :key="'section-' + s.id"
              @click="filterBySection(s)"
            >{{ s.title }}</button>

            <button
              type="button"
              class="btn btn-xs btn-light me-2"
              :class="subSectionFilter === ss ? 'border-primary' : ''"
              v-for="ss in subSections"
              :key="'sub-section-' + ss.id"
              @click="filterBySubSection(ss)"
            >{{ ss.title }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="timeline" id="timeline">
      <div
        class="timeline-container"
        v-for="(i, key) in filteredItems"
        :key="i.id"
        :class="checkKey(key)"
      >
        <div class="timeline-header" v-if="i.section.section">
          <p class="fw-bold mb-0">
            {{ i.section.section.title }}
            <small>/ {{ i.section.title }}</small>
          </p>
        </div>
        <div class="timeline-sub-header" v-else>
          <p class="mb-0">{{ i.section.title }}</p>
        </div>
        <div class="timeline-content">
          <div class="editorContent"  v-html="i.content"></div>
        </div>
        <div class="timeline-time text-end">
          <small class="text-capitalize">{{ i.created_at | formateTimeAgo }} /</small>
          <small>{{ i.created_at | formateDate }}</small>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col my-auto">
          <button class="btn btn-sm btn-light" @click="DownloadPDF()">Download PDF</button>
        </div>
     </div>
  </div>
</template>

<script>
export default {
  props: ["timeline", "client"],
  data() {
    return {
      filteredItems: this.items,
      sectionFilter: null,
      subSectionFilter: null,
    };
  },
  computed: {
    items() {
      var ti = [];
      this.timeline.sections.forEach(s => {
        if (s.items) {
          s.items.forEach(i => {
            ti.push(i);
          });
        }
        if (s.sub_sections) {
          s.sub_sections.forEach(ss => {
            ss.items.forEach(i => {
              ti.push(i);
            });
          });
        }
      });

      //sort latest items first
      ti.sort(function(a, b) {
        return a.created_at < b.created_at ? 1 : -1;
      });

      return ti;
    },
    sections() {
      var ts = [];
      this.timeline.sections.forEach(section => {
        ts.push(section);
      });
      return ts;
    },
    subSections() {
      var tss = [];
      this.timeline.sections.forEach(s => {
        if (s.sub_sections) {
          s.sub_sections.forEach(ss => {
            tss.push(ss);
          });
        }
      });
      return tss;
    }
  },
  methods: {
    DownloadPDF() {
      this.$nextTick(() => {
        const element = document.getElementById("timeline");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);

      });
    },
    filterBySection(section) {
      this.sectionFilter = section;
      this.subSectionFilter = null;

      this.filteredItems = this.items.filter(el => {
        if (el.section.section && el.section.section.id == this.sectionFilter.id) {
          return true;
        }
        else if (el.section.id == this.sectionFilter.id) {
          return true;
        }
      });
    },
    filterBySubSection(section) {
      this.subSectionFilter = section;
      this.sectionFilter = null;

      this.filteredItems = this.items.filter(el => {
        if (el.section.id == this.subSectionFilter.id) {
          return true;
        }
      });
    },
    checkKey(key) {
      if (key % 2) {
        return "right";
      } else {
        return "left";
      }
    },
    clearFilters() {
      this.filteredItems = this.items;
      this.subSectionFilter = null;
      this.sectionFilter = null;
    }
  },
  filters: {
    formateTimeAgo(date) {
      return moment(date).fromNow();
    },
    formateDate(date) {
      return moment(date).format("LLLL");
    }
  },
  mounted() {
    this.filteredItems = this.items;
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #ccc;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: #ccc;
  border: 4px solid #03006b;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 45px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #ccc;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #ccc;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 45px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #ccc;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ccc transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* The actual content */
.timeline-content {
  padding: 20px 30px;
  background-color: #ccc;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid #ccc;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ccc transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
</style>