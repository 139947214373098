<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card border-0 bg-light shadow-none">
          <div class="card-body">
            <ul class="list-group-flush sections">
              <div class="row mb-2">
                <div class="col my-auto">
                  <h4>
                    <span v-if="editTitle === false">
                      {{ timeline.title }}
                      <small @click="editTitle = true"><i class="fas fa-edit cursor-pointer"></i></small>
                    </span>
                    <span v-else>
                      <div class="form-inline">
                      <input type="text" v-model="timeline.title" class="form-control form-control-sm w-25 me-2" />
                      <small @click="updateTimeline()"><i class="fas fa-save cursor-pointer"></i></small>
                      </div>
                    </span>
                  </h4>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <a
                    data-target="#"
                    class="text-primary cursor-pointer me-3"
                    @click="addSection = !addSection"
                  >
                    <small>{{ addSection ? "Close" : "Add Section" }}</small>
                  </a>

                  <a
                    data-target="#"
                    class="text-primary cursor-pointer"
                    @click="deleteTimeline"
                  >
                    <small>Delete Timeline</small>
                  </a>
                </div>
              </div>

              <section-part
                class="mb-3"
                v-for="h in timeline.sections"
                :key="h.id"
                :section="h"
                :timeline="timeline"
                :client="client"
              ></section-part>
              <!--  -->
              <div class="row">
                <div class="col my-auto">
                  <form @submit.prevent="createSection" v-if="addSection">
                    <div class="row">
                      <div class="col my-auto">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="Add a section"
                          v-model="section"
                        />
                      </div>
                      <div class="col-auto ms-auto my-auto">
                        <button type="submit" class="btn btn-sm btn-primary">
                          <i class="far fa-save me-2"></i>Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <!--  -->

              <!--  -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Section from "./Section";

export default {
  props: ["timeline", "client"],
  data() {
    return {
      section: "",
      addSection: false,
      editTitle: false,
    };
  },
  methods: {
    updateTimeline() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.timeline.id +
            "/update-timeline",
          {
            title: this.timeline.title,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.editTitle = false;
        });
    },
    createSection() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/sections",
          {
            section: this.section,
            client_timeline_id: this.timeline.id,
          }
        )
        .then(({ data }) => {
          this.section = "";
          this.addSection = false;
          if (this.timeline.sections) {
            this.timeline.sections.push(data.section);
          } else {
            this.timeline.sections = [];
            this.timeline.sections.push(data.section);
          }
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteTimeline() {
      const confRes = confirm(
        "Are you sure you wish to delete this client timeline and all associated data? This cannot be undone!"
      );
      if (!confRes) {
        return;
      }

      this.$axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/" +
            this.timeline.id +
            "/delete"
        )
        .then(({ data }) => {
          let obj = this.$parent.timelines.find((o) => o.id == this.timeline.id);
          var index = this.$parent.timelines.indexOf(obj);
          this.$parent.timelines.splice(index, 1);
          if (this.$parent.timelines.length === 0) {
            this.$parent.selectedTimeline = "";
          }
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  mounted() {},
  components: {
    "section-part": Section,
  },
};
</script>

<style>
</style>