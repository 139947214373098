
<template>
  <div>
    <li class="list-group-item">
      <div class="card border-0 bg-light shadow-none">
        <div class="card-body">
          <div v-if="!edit">
            <div class="editorContent" v-html="item.content"></div>
          </div>
          <div v-else>
            <form @submit.prevent="saveItem">
              <div class="row">
                <div class="col my-auto">
                  <vue-editor
                    v-model="item.content"
                    class="bg-white"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <button type="submit" class="btn btn-sm btn-primary">
                    <i class="far fa-save me-2"></i>Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <small v-if="!edit" @click="edit = true" class="cursor-pointer"
            ><i class="far fa-edit me-1"></i>Edit</small
          >
          <small v-if="edit" @click="edit = false" class="cursor-pointer"
            ><i class="far fa-times me-1"></i>Cancel</small
          >
        </div>
        <div class="col-auto ms-auto">
          <small
            >{{ item.user.name }} / {{ item.updated_at | formatDate }}</small
          >
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import debounce from "debounce";
export default {
  props: ["client", "item"],
  data() {
    return {
      edit: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  beforeDestroy() {
    console.log('Closing timeline item');
    this.saveItem();
  },
  methods: {
    autosaveItem: debounce( function() {
      this.updateItem();
    }, 300),
    saveItem() {
      this.updateItem();
      this.edit = false;
    },
    updateItem() {
      return this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/items/" +
            this.item.id,
          this.item
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  watch: {
    edit(newVal) {
      this.$stash.timelineEditing = newVal;
    },
    "item.content": function (newVal, oldVal) {
      if (this.edit) {
        this.autosaveItem();
      }
    },
  },
};
</script>

<style>
</style>
