<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-3">
        <div class="col-auto my-auto">
          <h5 class="mb-0 client-tab-heading">Timeline</h5>
        </div>
        <div class="col text-end">
          <form
              @submit.prevent="createTimelineFromTemplate"
              v-if="!selectedTimeline && templates.length > 0"
          >
            <div class="d-flex">
              <div class="col-auto ms-auto my-auto">
                <select
                    v-model="selectedTemplate"
                    class="form-control form-control-sm"
                >
                  <option value disabled>Select a template</option>
                  <option v-for="t in templates" :key="t.id" :value="t.id">
                    {{ t.title }}
                  </option>
                </select>
              </div>
              <div class="col-auto my-auto">
                <button type="submit" class="btn btn-sm btn-outline-primary">
                  <i class="far fa-check me-2"></i>
                  Create Timeline from Template
                </button>
              </div>
            </div>
          </form>
          <div
              class="col-auto ms-auto"
              v-if="!selectedTimeline && templates.length == 0"
          >
            <a
                href="/settings/timelines"
                class="btn btn-sm btn-outline-primary"
            >
              <i class="far fa-cogs me-2"></i>Create Template in Timeline
              Settings
            </a>
          </div>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <!--  -->

      <div class="row mb-3" v-if="!selectedTimeline">
        <div class="col">
          <p class="alert alert-primary">
            <i class="far fa-info me-2"></i>The client may have multiple
            timelines at once. You may add, remove or modify sections and add as
            many items to each section or sub-section as you wish.
          </p>
        </div>
      </div>

      <!--  -->

      <div class="row" v-if="timelines.length > 0">
        <div class="col-auto">
          <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="viewAsTimeline = !viewAsTimeline"
              v-if="selectedTimeline && !viewAsTimeline"
          >
            <i class="far fa-toggle-off me-2"></i>Switch to Timeline view
          </button>

          <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="viewAsTimeline = !viewAsTimeline"
              v-if="selectedTimeline && viewAsTimeline"
          >
            <i class="far fa-toggle-on me-2"></i>Switch to Builder view
          </button>
        </div>
        <div class="col my-auto ms-auto" v-if="!selectedTimeline">
          <ul class="list-group-flush">
            <li
                class="list-group-item py-3"
                v-for="t in timelines"
                :key="t.id"
                :value="t"
            >
              <div class="row">
                <div class="col">{{ t.title }}</div>
                <div class="col-auto">
                  <button
                      class="btn btn-outline-primary btn-sm"
                      @click="selectedTimeline = t"
                  >
                    View Timeline
                  </button>

                  <button
                      class="btn btn-outline-danger btn-sm ms-2"
                      @click="deleteTimeline(t)"
                  >
                    Delete Timeline
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-auto my-auto ms-auto">
          <button
              class="btn btn-sm btn-light"
              v-if="selectedTimeline"
              @click="selectedTimeline = ''"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>
      <hr />
      <builder-view
          v-if="selectedTimeline && !viewAsTimeline"
          :timeline="selectedTimeline"
          :client="client"
          ref="timelineBuilder"
      ></builder-view>
      <timeline-view
          v-if="selectedTimeline && viewAsTimeline"
          :timeline="selectedTimeline"
          :client="client"
      ></timeline-view>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import BuilderView from "./timeline-partials/View";
import TimelineView from "./timeline-partials/TimelineView";

export default {
  props: ["clientObj"],
  data() {
    return {
      client: null,
      templates: [],
      selectedTemplate: "",
      timelines: [],
      selectedTimeline: "",
      viewAsTimeline: false,
      redirectTo: "",
    };
  },
  methods: {
    deleteTimeline(timeline) {
      if (
          confirm(
              "Are you sure you wish to delete this client timeline and all associated data? This cannot be undone!"
          )
      ) {
        this.$axios
            .delete(
                process.env.VUE_APP_API_URL +
                "/clients/api/" +
                this.client.id +
                "/timelines/" +
                timeline.id +
                "/delete"
            )
            .then(({ data }) => {
              this.fetchClientTimelines();
              this.$EventBus.$emit("alert", data);
            });
      }
    },
    fetchAvailableTemplates() {
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/settings/timelines/api")
          .then(({ data }) => {
            this.templates = data;
          });
    },
    createTimelineFromTemplate() {
      if (!this.selectedTemplate) {
        this.$EventBus.$emit("alert", {
          message: "Please select a Timeline template",
          color: "warning",
        });
        return false;
      }
      this.$axios
          .post(
              process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/create-timeline-from-template/" +
              this.selectedTemplate
          )
          .then(({ data }) => {
            this.timelines.push(data.timeline);
            this.selectedTimeline = data.timeline;
          });
    },
    fetchClientTimelines() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/fetch-timelines"
          )
          .then(({ data }) => {
            this.timelines = data;
          });
    },
    async fetchClientData() {
      if (this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
          process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;

    this.$stash.timelineEditing = false;

    await this.fetchClientData();
    this.fetchAvailableTemplates();
    this.fetchClientTimelines();
  },
  components: {
    BuilderView,
    TimelineView,
  },
};
</script>

<style>
.editorContent p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.42;
}
</style>
