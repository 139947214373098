<template>
  <div>
    <li class="list-group-item sub-headings border mb-2">
      <div class="row mb-3">
        <div class="col my-auto">
          <h6 class="mb-0">{{ subSection.title }}</h6>
        </div>
        <div class="col-auto ms-auto my-auto">
          <a
            class="text-primary cursor-pointer"
            data-target="#"
            @click="deleteSection"
          >
            <small>Delete</small>
          </a>
        </div>
      </div>

      <!--  -->
      <item
        v-for="i in subSection.items"
        :key="i.id"
        :item="i"
        :client="client"
        :timeline="timeline"
        :section="subSection"
      ></item>

      <!--  -->

      <div class="row">
        <div class="col-auto">
          <a
            data-target="#"
            class="text-primary cursor-pointer me-3"
            @click="addItem = !addItem"
          >
            <small>{{ addItem ? "Close" : "Add Detail / Answer" }}</small>
          </a>
        </div>
        <div class="col my-auto">
          <form @submit.prevent="createItem" v-if="addItem">
            <div class="row">
              <div class="col my-auto">
                <!-- <textarea
                  type="text"
                  class="form-control form-control-sm"
                  :placeholder="'Add detail relating to ' + subSection.title"
                  rows="3"
                  v-model="item"
                ></textarea>-->
                <vue-editor
                  v-model="item"
                  class="bg-white"
                  :placeholder="'Add detail relating to ' + subSection.title"
                  :editor-toolbar="customToolbar"
                ></vue-editor>
              </div>
              <div class="col-auto ms-auto my-auto">
                <button type="submit" class="btn btn-sm btn-primary">
                  <i class="far fa-save me-2"></i>Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import Item from "./Item";

export default {
  props: ["subSection", "timeline", "client"],
  data() {
    return {
      addItem: false,
      item: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  beforeDestroy() {
    console.log('Closing timeline subsection');
    if(this.subSection && this.item) {
      this.createItem();
    }
  },
  methods: {
    createItem() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/items",
          {
            client_timeline_section_id: this.subSection.id,
            content: this.item,
          }
        )
        .then(({ data }) => {
          if (this.subSection.items) {
            this.subSection.items.push(data.item);
          } else {
            this.subSection.items = [];
            this.subSection.items.push(data.item);
          }
          this.item = "";
          this.addItem = false;

          this.$EventBus.$emit("alert", data);
        });
    },
    deleteSection() {
      if (!confirm("Are you sure you wish to remove this section?")) {
        return;
      }
      this.$axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/" +
            this.subSection.id
        )
        .then(({ data }) => {
          let obj = this.$parent.section.sub_sections.find((o) => o.id == this.subSection.id);
          var index = this.$parent.section.sub_sections.indexOf(obj);
          this.$nextTick(() => {
            this.$parent.section.sub_sections.splice(index, 1);
          });
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  watch: {
    addItem(newVal) {
      this.$stash.timelineEditing = newVal;
    },
  },
  components: {
    Item,
  },
};
</script>

<style>
</style>