<template>
  <div>
    <li class="list-group-item">
      <div class="row mb-2">
        <div class="col my-auto">
          <h5 class="mb-0">{{ section.title }}</h5>
        </div>
        <div class="col-auto ms-auto my-auto">
          <a
            data-target="#"
            class="text-primary cursor-pointer me-3"
            @click="addSection = !addSection"
          >
            <small>{{ addSection ? "Close" : "Add Sub-Section" }}</small>
          </a>
          <a
            class="text-primary cursor-pointer"
            data-target="#"
            @click="deleteSection"
          >
            <small>Delete</small>
          </a>
        </div>
      </div>

      <!--  -->
      <ul class="list-group-flush sections">
        <!--  -->
        <span
          v-if="subSections && subSections.length"
        >
          <sub-section
            v-for="sh in subSections"
            :key="sh.id"
            :sub-section="sh"
            :timeline="timeline"
            :client="client"
            class="mb-3"
          ></sub-section>
        </span>

        <item
          v-for="i in section.items"
          :key="i.id"
          :item="i"
          :client="client"
          :timeline="timeline"
          :section="section"
        ></item>

        <div class="row">
          <div class="col my-auto">
            <form @submit.prevent="createSection" v-if="addSection">
              <div class="row">
                <div class="col my-auto">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Add a section"
                    v-model="subSection"
                  />
                </div>
                <div class="col-auto ms-auto my-auto">
                  <button type="submit" class="btn btn-sm btn-primary">
                    <i class="far fa-save me-2"></i>Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col-auto">
            <a
              data-target="#"
              class="text-primary cursor-pointer me-3"
              @click="addItem = !addItem"
            >
              <small>{{ addItem ? "Close" : "Add Detail / Answer" }}</small>
            </a>
          </div>
          <div class="col my-auto">
            <form @submit.prevent="createItem" v-if="addItem">
              <div class="row">
                <div class="col my-auto">
                  <!-- <textarea
                    type="text"
                    class="form-control form-control-sm"
                    :placeholder="'Add detail relating to ' + section.title"
                    rows="3"
                    v-model="item"
                  ></textarea>-->
                  <vue-editor
                    v-model="item"
                    class="bg-white"
                    :placeholder="'Add detail relating to ' + section.title"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <button type="submit" class="btn btn-sm btn-primary">
                    <i class="far fa-save me-2"></i>Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!--  -->
      </ul>
    </li>
  </div>
</template>

<script>
import SubSection from "./SubSection";
import Item from "./Item";

export default {
  props: ["section", "timeline", "client"],
  data() {
    return {
      subSection: "",
      subSections: [],
      addSection: false,
      addItem: false,
      item: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  mounted() {
    if (this.section.sub_sections) {
      this.subSections = this.section.sub_sections
    }
  },
  methods: {
    createSection() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/sections",
          {
            section: this.subSection,
            client_timeline_id: this.timeline.id,
            client_timeline_section_id: this.section.id,
          }
        )
        .then(({ data }) => {
          this.subSection = "";
          if (this.section.sub_sections) {
            this.section.sub_sections.push(data.section);
          } else {
            this.section.sub_sections = [];
            this.section.sub_sections.push(data.section);
          }
          this.subSections = this.section.sub_sections;
          this.$EventBus.$emit("alert", data);
        });
    },
    createItem() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/items",
          {
            client_timeline_section_id: this.section.id,
            content: this.item,
          }
        )
        .then(({ data }) => {
          if (this.section.items) {
            this.section.items.push(data.item);
          } else {
            this.section.items = [];
            this.section.items.push(data.item);
          }
          this.item = "";
          this.addItem = false;
          this.$EventBus.$emit("alert", data);
        });
    },

    deleteSection() {
      if (!confirm("Are you sure you wish to remove this section?")) {
        return;
      }
      this.$axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/timelines/" +
            this.section.id
        )
        .then(({ data }) => {
          let obj = this.timeline.sections.find((o) => o.id == this.section.id);
          var index = this.timeline.sections.indexOf(obj);
          this.timeline.sections.splice(index, 1);
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  watch: {
    addItem(newVal) {
      this.$stash.timelineEditing = newVal;
    },
  },
  components: {
    SubSection,
    Item,
  },
};
</script>

<style>
</style>